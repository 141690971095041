import React, {useEffect, useState} from 'react';
import axios, {get} from "axios";
import {
    Autoplay,
    EffectCards,
    EffectCoverflow,
    EffectCreative,
    EffectFade, EffectFlip,
    Navigation,
    Pagination
} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import {QRCode} from "antd";

const WidgetNobetciEczaneComponent = () => {
    const [nobetciEczane, setNobetciEczane] = useState([]);

    const getData = () => {
        axios.get("https://ileti.orbel.com.tr/nobetci-eczane/index.php?parametre=701")
            .then(async res => {
                setNobetciEczane(res.data)
            })
            .catch(err => {
                console.error("Hata oluştu:", err);
            });
    }

    useEffect(() => {
        getData()

        const intervalId = setInterval(() => {
            getData();
        }, 1200000);

        // Unmounting logic
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return(<div style={{position: "absolute",  width: "100%", top:-40, paddingLeft:0,paddingRight:10, zIndex: 2}}>
        <ul style={{display: "flex",}}>
            <Swiper
                spaceBetween={14}
                slidesPerView={1}
                effect={"coverflow"}
                animation={"fade"}
                speed={2500}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                loop={true}
                modules={[EffectCoverflow, Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                {nobetciEczane.map((item, index) => {
                    return (
                        <SwiperSlide>
                            <div style={{
                                display: "flex",
                                justifyContent: "left",
                                gap: 10,
                                alignItems: "center",
                                alignContent: "center",
                                backgroundColor: 'red',
                                borderRadius: 10,
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 5,
                                paddingBottom: 5
                            }}>
                                <div style={{display:"flex", width:"100%", justifyContent:"space-between"}}>
                                    <div style={{display:"flex", alignItems:"center", gap:10}}>
                                        <div style={{fontSize: 18, color: "white"}}><img alt={"Nöbetçi Eczane"}
                                                                                         width={30}
                                                                                         src={"https://images.seeklogo.com/logo-png/53/1/eczane-logo-png_seeklogo-536923.png"}></img>
                                        </div>
                                        <div
                                            style={{fontSize: 18, fontWeight: "bold", color: "white"}}>{item.isim}</div>
                                        <div style={{fontSize: 15, color: "white"}}>{item.adres}</div>
                                    </div>

                                    <div style={{display: "flex", alignItems: "center", gap:10}}>
                                        <div style={{fontSize: 15, fontWeight:"bold", color: "white"}}>{item.ekstra_adres}</div>

                                        <div style={{
                                            fontSize: 20,
                                            fontWeight: "bold",
                                            color: "white"
                                        }}>{item.telefon}</div>

                                    </div>

                                </div>

                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>


        </ul>
    </div>)
}

export default WidgetNobetciEczaneComponent
